.nav__bar {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(17, 23, 34, 0.8);
}
.nav__bar ul {
  width: 100%;
}
.nav__bar ul li a {
  color: #fff;
}
.nav__bar ul li a:hover, .nav__bar ul li a:focus {
  color: #fff;
}/*# sourceMappingURL=style.css.map */