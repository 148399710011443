.row {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.footer {
  background-color: rgba(38, 36, 52, 0.3019607843);
}

.footer__Sosials {
  display: flex;
  justify-self: center;
  align-items: center;
  margin: 4% 0;
}

.footer__Sosials__imag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.footer__Sosials__imag img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 2px solid;
  padding: 8px;
}

.footer__Sosials__content {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.footer__Sosials__content .list-unstyled {
  display: flex;
  flex-flow: row;
  justify-self: center;
}
.footer__Sosials__content .list-unstyled li {
  padding: 0 4px;
}
.footer__Sosials__content .list-unstyled li a {
  color: #fff;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
  font-size: 27px;
}
.footer__Sosials__content .list-unstyled li a:hover {
  color: #fff;
}
.footer__Sosials__content h2 {
  margin: 10px 0 5px 0;
}
.footer__Sosials__content h6 {
  margin: 10px 0 5px 0;
}/*# sourceMappingURL=style.css.map */