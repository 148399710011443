.row {
  padding: 0 0;
  margin: 0 0;
}

.header .header-img {
  -webkit-mask-image: url(data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoNjEsIDYwLCA2NiwgMSkiIG9mZnNldD0iMCUiIGRhdGEtZGFya3JlYWRlci1pbmxpbmUtc3RvcGNvbG9yPSIiIHN0eWxlPSItLWRhcmtyZWFkZXItaW5saW5lLXN0b3Bjb2xvcjojYTkzYTA2OyI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDYxLCA2MCwgNjYsIDEpIiBvZmZzZXQ9IjEwMCUiIGRhdGEtZGFya3JlYWRlci1pbmxpbmUtc3RvcGNvbG9yPSIiIHN0eWxlPSItLWRhcmtyZWFkZXItaW5saW5lLXN0b3Bjb2xvcjojYjk3NDAzOyI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InJnYmEoNjEsIDYwLCA2NiwgMSkiIGQ9Ik0xOS40LC0zMy44QzI2LjEsLTI5LjYsMzMuMywtMjYuNSwzOC40LC0yMS4xQzQzLjQsLTE1LjYsNDYuMywtNy44LDQ2LjQsMC4xQzQ2LjYsNy45LDQzLjksMTUuOSwzOS4zLDIyQzM0LjYsMjguMiwyOCwzMi41LDIxLjEsMzYuN0MxNC4yLDQwLjksNy4xLDQ1LDAuMSw0NC44Qy02LjksNDQuNiwtMTMuOCw0MC4yLC0xOS4yLDM1LjFDLTI0LjYsMzAuMSwtMjguNiwyNC41LC0zMi41LDE4LjVDLTM2LjQsMTIuNSwtNDAuMiw2LjMsLTQxLjgsLTAuOUMtNDMuNCwtOC4yLC00Mi45LC0xNi4zLC0zOC45LC0yMkMtMzQuOCwtMjcuNywtMjcuMiwtMzAuOSwtMjAuMiwtMzQuOUMtMTMuMSwtMzguOSwtNi42LC00My44LC0wLjEsLTQzLjZDNi4zLC00My40LDEyLjYsLTM4LjEsMTkuNCwtMzMuOFoiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiIHN0cm9rZT0idXJsKCNzdy1ncmFkaWVudCkiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=);
  -webkit-mask-repeat: no-repeat;
}
.header .header-img img {
  width: 90%;
}
.header .header-text {
  padding: 23% 0 0 0;
  color: #fff;
}
.header .header-text h4 {
  font-size: 45px;
  font-weight: 600;
  font-family: inherit;
}
.header .header-text p {
  width: 80%;
  font-size: 20px;
  line-height: 30px;
}
.header .header-text h2 {
  margin: 14px 0px 5px 0;
}
.header .header-text .header-buttons a {
  border: 1px solid #fff;
  width: 94px;
  font-size: 18px;
  font-weight: 600;
}
.header .header-text .header-buttons a:first-child {
  margin-right: 10px;
  background-color: #fff;
}
.header .header-text .header-buttons a:last-child {
  margin-left: 10px;
  color: #fff;
  width: 150px;
}
.header .header-text .list-unstyled {
  margin: 19px 0 0 0;
  display: flex;
  justify-self: center;
  align-items: center;
}
.header .header-text .list-unstyled li {
  display: inline;
  padding: 0 4px;
}
.header .header-text .list-unstyled li a {
  color: #fff;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
  font-size: 27px;
}
.header .header-text .list-unstyled li a:hover {
  color: #fff;
}/*# sourceMappingURL=style.css.map */