// * {
//   border: 1px solid red;
// }
.row {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.footer {
  background-color: #2624344d;
}

.footer__Sosials {
  display: flex;
  justify-self: center;
  align-items: center;
  margin: 4% 0;
}

.footer__Sosials__imag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 2px solid;
    padding: 8px;
  }
}

.footer__Sosials__content {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  .list-unstyled {
    display: flex;
    flex-flow: row;
    justify-self: center;
    li {
      padding: 0 4px;

      a {
        color: #fff;
        font-size: 20px;
        margin: 0px 10px 0px 0px;
        font-size: 27px;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  h2 {
    margin: 10px 0 5px 0;
  }
  h6 {
    margin: 10px 0 5px 0;
  }
}
