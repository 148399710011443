.nav__bar {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #111722cc;

  ul {
    width: 100%;
    li {
      a {
        color: #fff;

        &:hover,
        &:focus {
          color: #fff;
        }
      }
    }
  }
}
