.project-header {
  h2 {
    text-align: center;
    margin: 24px 0;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    margin-top: 0;
  }
}
